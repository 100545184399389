































































































































import { Component, Mixins } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Settings16 from '@carbon/icons-vue/es/settings/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import Add16 from '@carbon/icons-vue/es/add/16'
import axios from 'axios'

import NotificationMixin from '@/mixins/notificationMixin'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    Settings16,
    TrashCan16,
    Edit16,
    Add16
  }
})
export default class UsersRoles extends Mixins(NotificationMixin, PermissionsMixin) {
  createModalIsOpened = false
  deleteModalIsOpened = false
  tableTitle = ''
  data = []
  filteredData = []
  response: Record<string, any> = {}

  createForm = {
    name: '',
    errors: {
      name: ''
    }
  }

  deleteForm = {
    name: '',
    errors: {
      name: ''
    }
  }

  toggleModal (modalName: string) {
    this.$data[modalName] = !this.$data[modalName]
  }

  created () {
    this.getRoles()
  }

  getRoles () {
    axios.get('roles/user')
      .then(response => {
        this.data = response.data.data
        this.filteredData = this.data
        this.response = response.data
        this.tableTitle = `${this.response.data.length} papéis encontrados`
      })
  }

  submitNewRole () {
    axios.post('roles/store', this.createForm)
      .then(() => {
        this.toggleModal('createModalIsOpened')
        this.setNotification('success', `O papel ${this.createForm.name} foi cadastrado`)
        this.resetCreateForm()
      })
      .catch(err => {
        if (err.response.status === 422) {
          const response = err.response.data

          if (response.errors) {
            const { name } = response.errors
            this.createForm.errors.name = name[0]
          } else if (response.error) {
            this.createForm.errors.name = response.error
          }
        }
      })
      .then(this.getRoles)
  }

  resetCreateForm () {
    this.createForm = {
      name: '',
      errors: {
        name: ''
      }
    }
  }

  submitDeleteRole () {
    // TODO: Add password confirmation, maybe?
    axios.delete('roles/delete', { data: this.deleteForm })
      .then(() => {
        this.setNotification('success', `O papel ${this.deleteForm.name} foi removido`)
        this.toggleModal('deleteModalIsOpened')
        this.resetDeleteForm()
      })
      .then(this.getRoles)
  }

  resetDeleteForm () {
    this.deleteForm = {
      name: '',
      errors: {
        name: ''
      }
    }
  }

  search (searchString: string) {
    this.filteredData = this.data.filter((role: Role) => {
      let { name } = role
      name = name.toLowerCase()
      searchString = searchString.toLowerCase()

      if (name.includes(searchString)) {
        return role
      }
    })
  }
}
